<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Junta de Aclaración</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons
            :tenderId="this.tenderId"
            :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Junta de Aclaraciones 6.0
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Parrafo por Defecto</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Parrafo por Defecto</h1>
                        <trumbowyg
                            v-model="data.parrafo_default"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Parrafo por Defecto"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Dudas y respuesas proveedores</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Dudas y respuesas proveedores
                        </h1>
                        <trumbowyg
                            v-model="data.dudas_respuestas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Dudas y respuesas proveedores"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Usuario Requiriente</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Usuario Requiriente
                        </h1>
                        <trumbowyg
                            v-model="data.usuario_requirente"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Usuario Requiriente"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
                <v-col cols="12" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn @click="generateDataMinutes" color="primary" style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServerMinutes" color="primary"
                         style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatus">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerate"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#constanciaPublicacion" @click="findOnPublication">
            Constancia de Publicacion 6.1
          </v-tab>
          <v-tab-item value="constanciaPublicacion">
            <v-form ref="formPublicacion" class="mt-10" @submit.prevent="submitFormPublicacion">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Hora y Minutos</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Hora y Minutos</h1>
                        <trumbowyg
                            v-model="dataPublication.hora_minutos"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Hora y Minutos"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Fecha en Letra
                        </h1>
                        <trumbowyg
                            v-model="dataPublication.fecha_letra"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Fecha en Letra"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="6" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn @click="generateDataPub" v-if="idConstancy" color="primary" style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatusConstancy" @click="downloadFileServerPub"
                         color="primary" style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatusConstancy">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerateConstancy"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#constanciaRetiro" @click="findOneRetirement">
            Constancia de retiro 6.2
          </v-tab>
          <v-tab-item value="constanciaRetiro">
            <v-form ref="formRet" class="mt-10" @submit.prevent="submitFormRetiro">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Hora y Minutos</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Hora y Minutos</h1>
                        <trumbowyg
                            v-model="dataRetirement.hora_minutos"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Hora y Minutos"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Fecha en Letra
                        </h1>
                        <trumbowyg
                            v-model="dataRetirement.fecha_letra"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Fecha en Letra"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Publicación dia</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Publicación dia</h1>
                        <trumbowyg
                            v-model="dataRetirement.textDate"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Publicación dia"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="6" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn @click="generateDataRet" v-if="idRetirement" color="primary" style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatusRet" @click="downloadFileServerRet" color="primary"
                         style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatusRet">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerateRet"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#constanciaAsist" @click="findOneAttendanceList">
            Lista de asistencia 6.3
          </v-tab>
          <v-tab-item value="constanciaAsist">
            <v-form ref="formRetiro" class="mt-10" @submit.prevent="submitFormAttendanceList">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Hora y Minutos</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Hora y Minutos</h1>
                        <trumbowyg
                            v-model="dataAttendanceList.hora_minutos"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Hora y Minutos"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Fecha en Letra
                        </h1>
                        <trumbowyg
                            v-model="dataAttendanceList.fecha_letra"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Fecha en Letra"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="6" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="mt-6">
                  <v-btn @click="generateDataAttendanceListData" v-if="idAttendanceList" color="primary"
                         style="width: 400px">
                    Generar Archivo
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatusAttendanceList"
                         @click="downloadFileServerAttendanceList" color="primary"
                         style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatusAttendanceList">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerateAttendanceList"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
<!--          <v-tab href="#vigilanciaOficio" @click="findOneSurveillance">-->
<!--            Oficio vigilancia 6.4-->
<!--          </v-tab>-->
<!--          <v-tab-item value="vigilanciaOficio">-->
<!--            <v-form ref="formOficioVigilancia" class="mt-10" @submit.prevent="submitFormOficioVigilancia">-->
<!--              <v-row class="mb-4">-->
<!--                <v-expansion-panels inset>-->
<!--                  <v-expansion-panel-->
<!--                  >-->
<!--                    <v-expansion-panel-header>Número de Oficio</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <v-col cols="12">-->
<!--                        <h1 class="mt-4 mx-2 ">Número de Oficio</h1>-->
<!--                        <trumbowyg-->
<!--                            v-model="dataSurveillance.numero_oficio"-->
<!--                            name="body"-->
<!--                            :config="configs.advanced"-->
<!--                            placeholder="Número de Oficio"-->
<!--                            class="form-control"-->
<!--                        ></trumbowyg>-->
<!--                      </v-col>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                  <v-expansion-panel-->
<!--                  >-->
<!--                    <v-expansion-panel-header>Hora y Minutos</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <v-col cols="12">-->
<!--                        <h1 class="mt-4 mx-2 ">Hora y Minutos</h1>-->
<!--                        <trumbowyg-->
<!--                            v-model="dataSurveillance.hora_minutos"-->
<!--                            name="body"-->
<!--                            :config="configs.advanced"-->
<!--                            placeholder="Hora y Minutos"-->
<!--                            class="form-control"-->
<!--                        ></trumbowyg>-->
<!--                      </v-col>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                  <v-expansion-panel-->
<!--                  >-->
<!--                    <v-expansion-panel-header>Fecha en Letra</v-expansion-panel-header>-->
<!--                    <v-expansion-panel-content>-->
<!--                      <v-col cols="12">-->
<!--                        <h1 class="mt-4 mx-2 ">Fecha en Letra-->
<!--                        </h1>-->
<!--                        <trumbowyg-->
<!--                            v-model="dataSurveillance.fecha_letra"-->
<!--                            name="body"-->
<!--                            :config="configs.advanced"-->
<!--                            placeholder="Fecha en Letra"-->
<!--                            class="form-control"-->
<!--                        ></trumbowyg>-->
<!--                      </v-col>-->
<!--                    </v-expansion-panel-content>-->
<!--                  </v-expansion-panel>-->
<!--                </v-expansion-panels>-->
<!--                <v-col cols="6" class="mt-4">-->
<!--                  <v-btn type="submit" color="primary" style="width: 200px">-->
<!--                    Guardar-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--                <v-col cols="6" class="mt-6">-->
<!--                  <v-btn @click="generateDataSU" v-if="idSurveillance" color="primary" style="width: 400px">-->
<!--                    Generar Archivo-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row class="mt-5">-->
<!--                <v-col cols="3">-->
<!--                  <v-btn type="button" v-if="documentGenerateStatusSurveillance" @click="downloadFileServerSU"-->
<!--                         color="primary"-->
<!--                         style="width: 200px">-->
<!--                    Descargar archivo-->
<!--                  </v-btn>-->
<!--                </v-col>-->
<!--                <v-col cols="12" v-if="documentGenerateStatusSurveillance">-->
<!--                  <h1 class="mt-4 mx-2 ">Visualización de documento-->
<!--                  </h1>-->
<!--                  <trumbowyg-->
<!--                      v-model="documentGenerateSurveillance"-->
<!--                      disabled="disabled"-->
<!--                      name="body"-->
<!--                      :config="configs.advanced"-->
<!--                      placeholder="Visualización de documento"-->
<!--                      class="form-control"-->
<!--                  ></trumbowyg>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-form>-->
<!--          </v-tab-item>-->
          <v-tab href="#filesData">
            Archivos
          </v-tab>
          <v-tab-item value="filesData">
            <v-row class="mb-4 mt-10">
              <v-expansion-panels inset>
                <v-expansion-panel @click="findOneFiles(1)"
                >
                  <v-expansion-panel-header>Acta Junta aclaraciones (Archivos) 6.5</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form v-if="!dataFileMeeting.id" ref="form" class="mt-10" @submit.prevent="submitFormFileMeeting">
                      <v-row class="mb-4">
                        <v-col cols="12">
                          <FileInput
                              :tenderId="tenderId"
                              :formatosId="dataFileMeeting.formatosId"
                              :file="dataFileMeeting.documento_url"
                              @getImageFile="handleImageFile"
                              @changeShowImage="handleShowImage"
                          />
                        </v-col>
                        <v-col cols="12" class="mt-4">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormUpdateFileMeeting">
                      <v-row class="mb-4" v-if="!overlay">
                        <v-col cols="12" v-if="charge1">
                          <FileInputEditMul
                              :tenderId="dataFileMeeting.tenderId"
                              :formatosId="dataFileMeeting.formatosId"
                              :file="dataFileMeeting.documento_url"
                              :file-tmp="dataFileMeeting.fileTmp"
                              :type-data="typeActually"
                              @getImageFile="handleImageFileAllUpdate"
                              @changeShowImage="handleShowImageMeetingUpdate"
                          />
                        </v-col>
                        <v-col v-if="dataFileMeeting.fileTmp" cols="6" class="mt-6">
                          <v-btn @click="downloadImage(dataFileMeeting.documento_url)" type="button" color="primary"
                                 style="width: 200px">
                            Descargar archivo
                          </v-btn>
                        </v-col>
                        <v-col v-if="!dataFileMeeting.fileTmp" cols="6" class="mt-6">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel @click="findOneFiles(2)"
                >
                  <v-expansion-panel-header>Constancia de publicacion (Archivos) 6.6</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form v-if="!dataFilePub.id" ref="form" class="mt-10" @submit.prevent="submitFormFilePub">
                      <v-row class="mb-4">
                        <v-col cols="12">
                          <FileInput
                              :tenderId="tenderId"
                              :formatosId="dataFilePub.formatosId"
                              :file="dataFilePub.documento_url"
                              @getImageFile="handleImageFilePub"
                              @changeShowImage="handleShowImagePub"
                          />
                        </v-col>
                        <v-col cols="12" class="mt-4">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormUpdateFilePub">
                      <v-row class="mb-4" v-if="!overlay">
                        <v-col cols="12" v-if="charge2">
                          <FileInputEditMul
                              :tenderId="dataFilePub.tenderId"
                              :formatosId="dataFilePub.formatosId"
                              :file="dataFilePub.documento_url"
                              :type-data="typeActually"
                              :file-tmp="dataFilePub.fileTmp"
                              @getImageFile="handleImageFileAllUpdate"
                              @changeShowImage="handleShowImagePubUpdate"
                          />
                        </v-col>
                        <v-col v-if="dataFilePub.fileTmp" cols="6" class="mt-6">
                          <v-btn @click="downloadImage(dataFilePub.documento_url)" type="button" color="primary"
                                 style="width: 200px">
                            Descargar archivo
                          </v-btn>
                        </v-col>
                        <v-col v-if="!dataFilePub.fileTmp" cols="6" class="mt-6">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel @click="findOneFiles(3)"
                >
                  <v-expansion-panel-header>Constancia de retiro (Archivos) 6.7</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form v-if="!dataFileConstant.id" ref="form" class="mt-10"
                            @submit.prevent="submitFormFileConstant">
                      <v-row class="mb-4">
                        <v-col cols="12" v-if="charge2">
                          <FileInput
                              :tenderId="tenderId"
                              :formatosId="dataFileConstant.formatosId"
                              :file="dataFileConstant.documento_url"
                              @getImageFile="handleImageFileConstant"
                              @changeShowImage="handleShowImageConstant"
                          />
                        </v-col>
                        <v-col cols="12" class="mt-4">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormUpdateFileConstant">
                      <v-row class="mb-4" v-if="!overlay">
                        <v-col cols="12" v-if="charge3">
                          <FileInputEditMul
                              :tenderId="dataFileConstant.tenderId"
                              :formatosId="dataFileConstant.formatosId"
                              :file="dataFileConstant.documento_url"
                              :file-tmp="dataFileConstant.fileTmp"
                              :type-data="typeActually"
                              @getImageFile="handleImageFileAllUpdate"
                              @changeShowImage="handleShowImageConstantUpdate"
                          />
                        </v-col>
                        <v-col v-if="dataFileConstant.fileTmp" cols="6" class="mt-6">
                          <v-btn @click="downloadImage(dataFileConstant.documento_url)" type="button" color="primary"
                                 style="width: 200px">
                            Descargar archivo
                          </v-btn>
                        </v-col>
                        <v-col v-if="!dataFileConstant.fileTmp" cols="6" class="mt-6">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel @click="findOneFiles(4)"
                >
                  <v-expansion-panel-header>Lista de asistencia (Archivos) 6.8</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form v-if="!dataFileAs.id" ref="form" class="mt-10" @submit.prevent="submitFormFileAs">
                      <v-row class="mb-4">
                        <v-col cols="12">
                          <FileInput
                              :tenderId="tenderId"
                              :formatosId="dataFileAs.formatosId"
                              :file="dataFileAs.documento_url"
                              @getImageFile="handleImageFileAs"
                              @changeShowImage="handleShowImageAs"
                          />
                        </v-col>
                        <v-col cols="12" class="mt-4">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormUpdateFileAs">
                      <v-row class="mb-4" v-if="!overlay">
                        <v-col cols="12" v-if="charge4">
                          <FileInputEditMul
                              :tenderId="dataFileAs.tenderId"
                              :formatosId="dataFileAs.formatosId"
                              :file="dataFileAs.documento_url"
                              :file-tmp="dataFileAs.fileTmp"
                              :type-data="typeActually"
                              @getImageFile="handleImageFileAllUpdate"
                              @changeShowImage="handleShowImageAsUpdate"
                          />
                        </v-col>
                        <v-col v-if="dataFileAs.fileTmp" cols="6" class="mt-6">
                          <v-btn @click="downloadImage(dataFileAs.documento_url)" type="button" color="primary"
                                 style="width: 200px">
                            Descargar archivo
                          </v-btn>
                        </v-col>
                        <v-col v-if="!dataFileAs.fileTmp" cols="6" class="mt-6">
                          <v-btn type="submit" color="primary" style="width: 200px">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
<!--                <v-expansion-panel @click="findOneFiles(5)"-->
<!--                >-->
<!--                  <v-expansion-panel-header>Oficio unidad de vigilancia (Archivos) 6.9</v-expansion-panel-header>-->
<!--                  <v-expansion-panel-content>-->
<!--                    <v-form v-if="!dataFileVg.id" ref="form" class="mt-10" @submit.prevent="submitFormFileVg">-->
<!--                      <v-row class="mb-4">-->
<!--                        <v-col cols="12">-->
<!--                          <FileInput-->
<!--                              :tenderId="tenderId"-->
<!--                              :formatosId="dataFileVg.formatosId"-->
<!--                              :file="dataFileVg.documento_url"-->
<!--                              @getImageFile="handleImageFileVg"-->
<!--                              @changeShowImage="handleShowImageVg"-->
<!--                          />-->
<!--                        </v-col>-->
<!--                        <v-col cols="12" class="mt-4">-->
<!--                          <v-btn type="submit" color="primary" style="width: 200px">-->
<!--                            Guardar-->
<!--                          </v-btn>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-form>-->
<!--                    <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormUpdateFileVg">-->
<!--                      <v-row class="mb-4" v-if="!overlay">-->
<!--                        <v-col cols="12" v-if="charge5">-->
<!--                          <FileInputEditMul-->
<!--                              :tenderId="dataFileVg.tenderId"-->
<!--                              :formatosId="dataFileVg.formatosId"-->
<!--                              :file="dataFileVg.documento_url"-->
<!--                              :file-tmp="dataFileVg.fileTmp"-->
<!--                              :type-data="typeActually"-->
<!--                              @getImageFile="handleImageFileAllUpdate"-->
<!--                              @changeShowImage="handleShowImageVgUpdate"-->
<!--                          />-->
<!--                        </v-col>-->
<!--                        <v-col v-if="dataFileVg.fileTmp" cols="6" class="mt-6">-->
<!--                          <v-btn @click="downloadImage(dataFileVg.documento_url)" type="button" color="primary"-->
<!--                                 style="width: 200px">-->
<!--                            Descargar archivo-->
<!--                          </v-btn>-->
<!--                        </v-col>-->
<!--                        <v-col v-if="!dataFileVg.fileTmp" cols="6" class="mt-6">-->
<!--                          <v-btn type="submit" color="primary" style="width: 200px">-->
<!--                            Guardar-->
<!--                          </v-btn>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-form>-->
<!--                  </v-expansion-panel-content>-->
<!--                </v-expansion-panel>-->
              </v-expansion-panels>
            </v-row>
          </v-tab-item>

        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import FileInput from "../../components/FileInput";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import FileInputEdit from "@/components/FileInputEdit.vue";
import {URL_API_IMAGES} from "@/store/config";
import FileInputEditMul from "@/components/FileInputEditMul.vue";

export default {
  name: "DetailClarificationMeetings",
  data() {
    return {
      overlay: false,
      typeActually: null,
      tenderId: null,
      externalId: null,
      idActa: null,
      idConstancy: null,
      idRetirement: null,
      idSurveillance: null,
      idAttendanceList: null,
      documentComplete: null,
      documentGenerate: null,
      documentGenerateRet: null,
      documentGenerateConstancy: null,
      documentGenerateAttendanceList: null,
      documentGenerateSurveillance: null,
      documentGenerateStatus: false,
      documentGenerateStatusConstancy: false,
      documentGenerateStatusRet: false,
      documentGenerateStatusAttendanceList: false,
      documentGenerateStatusSurveillance: false,
      charge1: false,
      charge2: false,
      charge3: false,
      charge4: false,
      charge5: false,
      id: null,
      formatosId: null,
      data: {
        parrafo_default: "",
        dudas_respuestas: "",
        usuario_requirente: "",
      },
      dataPublication: {
        hora_minutos: "",
        fecha_letra: "",
        formatosId: 22,
        tenderId: null,

      },
      dataRetirement: {
        hora_minutos: "",
        textDate: "",
        fecha_letra: "",
        formatosId: 23,
        tenderId: null,

      },
      dataAttendanceList: {
        hora_minutos: "",
        fecha_letra: "",
        formatosId: 24,
        tenderId: null,
      },
      dataSurveillance: {
        hora_minutos: "",
        fecha_letra: "",
        numero_oficio: "",
        formatosId: 25,
        tenderId: null,
      },
      dataFileMeeting: {
        id: null,
        documento_url: '',
        tenderId: null,
        formatosId: 26,
        fileTmp: '',
        fileSend: null,
      },
      dataFilePub: {
        id: null,
        documento_url: '',
        tenderId: null,
        formatosId: 27,
        fileTmp: '',
        fileSend: null,
      },
      dataFileConstant: {
        id: null,
        documento_url: '',
        tenderId: null,
        formatosId: 28,
        fileTmp: '',
        fileSend: null,
      },
      dataFileAs: {
        id: null,
        documento_url: '',
        tenderId: null,
        formatosId: 29,
        fileTmp: '',
        fileSend: null,
      },
      dataFileVg: {
        id: null,
        documento_url: '',
        tenderId: null,
        formatosId: 30,
        fileTmp: '',
        fileSend: null,
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    FileInputEditMul,
    CheckButtons,
    FileInput,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('ja_attendance', ['updateJAAttendance', 'findOneJAAttendace', 'generateHtmlFormatAtt', 'downloadFileAtt', 'createJAAttendace', 'updateJAAttendace']),
    ...mapActions('ja_attendance_list', ['updateJAAttendanceList', 'findOneJAAttendanceList', 'generateHtmlFormatAttendanceList', 'findOneJAAttendanceList', 'createJAAttendanceList', 'downloadFileAttendanceList']),
    ...mapActions('ja_minutes', ['updateJAMinutes', 'findOneJAMinutes', 'generateHtmlFormatJAMinutes', 'downloadFileJAMinutes']),
    ...mapActions('ja_publication', ['updateJAPublication', 'findOneJAPublication', 'generateHtmlFormatPub', 'downloadFilePub', 'createJAPublication']),
    ...mapActions('ja_retirement', ['updateJARetirement', 'findOneJARetirement', 'generateHtmlFormatRet', 'downloadFileRet', 'createJARetirement']),
    ...mapActions('ja_surveillance_unit', ['updateJASurveillanceUnit', 'findOneJASurveillanceUnit', 'generateHtmlFormatSU', 'downloadFileSU', 'createJASurveillanceUnit']),
    ...mapActions('tenders', ['findOneTenders']),
    // file meting 1 6.5
    async validateRequiredFileMeeting() {
      if (!this.dataFileMeeting.documento_url) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      return true

    },
    async submitFormFileMeeting() {
      const validateData = await this.validateRequiredFileMeeting();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createJAAttendace(this.dataFileMeeting);
      if (!response.error) {
        await this.findOneFiles(1);
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },

    async submitFormUpdateFileMeeting() {
      if (!this.dataFileMeeting.fileSend) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;
      const response = await this.updateJAAttendace({id: this.dataFileMeeting.id, data: this.dataFileMeeting.fileSend});
      if (!response.error) {
        await this.findOneFiles(1);
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },
    handleImageFile(file) {
      this.dataFileMeeting.documento_url = file;
    },
    handleShowImage(show) {
    },
    handleImageFileMeetingUpdate(file) {
      this.dataFileMeeting.documento_url = file;
      this.dataFileMeeting.fileSend = file;
      this.dataFileMeeting.fileTmp = '';
    },
    handleShowImageMeetingUpdate(show) {
    },
    //2 6.6
    async validateRequiredFilePub() {
      if (!this.dataFilePub.documento_url) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      return true

    },
    async submitFormFilePub() {
      const validateData = await this.validateRequiredFilePub();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createJAAttendace(this.dataFilePub);
      if (!response.error) {
        await this.findOneFiles(2);
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },

    async submitFormUpdateFilePub() {
      if (!this.dataFilePub.fileSend) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;
      const response = await this.updateJAAttendace({id: this.dataFilePub.id, data: this.dataFilePub.fileSend});
      if (!response.error) {
        await this.findOneFiles(2);
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },
    handleImageFilePub(file) {
      this.dataFilePub.documento_url = file;
    },
    handleShowImagePub(show) {
    },
    handleImageFilePubpdate(file) {
      this.dataFilePub.documento_url = file;
      this.dataFilePub.fileSend = file;
      this.dataFilePub.fileTmp = '';
    },
    handleImageFileAllUpdate(file, type) {

      this.charge1 = false;
      this.charge2 = false;
      this.charge3 = false;
      this.charge4 = false;
      this.charge5 = false;
      if (type === 1) {
        this.charge1 = true;
        this.dataFileMeeting.documento_url = file;
        this.dataFileMeeting.fileSend = file;
        this.dataFileMeeting.fileTmp = '';
      }
      if (type === 2) {
        this.charge2 = true;
        this.dataFilePub.documento_url = file;
        this.dataFilePub.fileSend = file;
        this.dataFilePub.fileTmp = '';
      }
      if (type === 3) {
        this.charge3 = true;
        this.dataFileConstant.documento_url = file;
        this.dataFileConstant.fileSend = file;
        this.dataFileConstant.fileTmp = '';
      }
      if (type === 4) {
        this.charge4 = true;
        this.dataFileAs.documento_url = file;
        this.dataFileAs.fileSend = file;
        this.dataFileAs.fileTmp = '';
      }
      if (type === 5) {
        console.log('wefwef')
        this.charge5 = true;
        this.dataFileVg.documento_url = file;
        this.dataFileVg.fileSend = file;
        this.dataFileVg.fileTmp = '';
      }

    },
    handleShowImagePubUpdate(show) {
    },

    //3 6.7
    async validateRequiredFileConstant() {
      if (!this.dataFileConstant.documento_url) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      return true

    },
    async submitFormFileConstant() {
      const validateData = await this.validateRequiredFileConstant();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createJAAttendace(this.dataFileConstant);
      if (!response.error) {
        await this.findOneFiles(3);
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },

    async submitFormUpdateFileConstant() {
      if (!this.dataFileConstant.fileSend) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;
      const response = await this.updateJAAttendace({
        id: this.dataFileConstant.id,
        data: this.dataFileConstant.fileSend
      });
      if (!response.error) {
        await this.findOneFiles(3);
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },
    handleImageFileConstant(file) {
      this.dataFileConstant.documento_url = file;
    },
    handleShowImageConstant(show) {
    },
    handleImageFileConstantUpdate(file) {
      this.dataFileConstant.documento_url = file;
      this.dataFileConstant.fileSend = file;
      this.dataFileConstant.fileTmp = '';
    },
    handleShowImageConstantUpdate(show) {
    },

    //4 6.8
    async validateRequiredFileAs() {
      if (!this.dataFileAs.documento_url) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      return true

    },
    async submitFormFileAs() {
      const validateData = await this.validateRequiredFileAs();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createJAAttendace(this.dataFileAs);
      if (!response.error) {
        await this.findOneFiles(4);
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },

    async submitFormUpdateFileAs() {
      if (!this.dataFileAs.fileSend) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;
      const response = await this.updateJAAttendace({id: this.dataFileAs.id, data: this.dataFileAs.fileSend});
      if (!response.error) {
        await this.findOneFiles(4);
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },
    handleImageFileAs(file) {
      this.dataFileAs.documento_url = file;
    },
    handleShowImageAs(show) {
    },
    handleImageFileAsUpdate(file) {
      this.dataFileAs.documento_url = file;
      this.dataFileAs.fileSend = file;
      this.dataFileAs.fileTmp = '';
    },
    handleShowImageAsUpdate(show) {
    },
    //5 6.9
    async validateRequiredFileVg() {
      if (!this.dataFileVg.documento_url) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      return true

    },
    async submitFormFileVg() {
      const validateData = await this.validateRequiredFileVg();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.createJAAttendace(this.dataFileVg);
      if (!response.error) {
        await this.findOneFiles(5);
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },

    async submitFormUpdateFileVg() {
      if (!this.dataFileVg.fileSend) {
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;
      const response = await this.updateJAAttendace({id: this.dataFileVg.id, data: this.dataFileVg.fileSend});
      if (!response.error) {
        await this.findOneFiles(5);
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },
    handleImageFileVg(file) {
      this.dataFileVg.documento_url = file;
    },
    handleShowImageVg(show) {
    },
    async handleImageFileVgUpdate(file) {
      this.dataFileVg.documento_url = file;
      this.dataFileVg.fileSend = file;
      this.dataFileVg.fileTmp = '';
    },
    handleShowImageVgUpdate(show) {
    },

    // file files
    async findOneFiles(type) {
      this.overlay = true
      this.typeActually = null;
      if (type === 1) {
        this.charge1 = false;
        this.dataFileMeeting.id = null;
        this.dataFileMeeting.documento_url = '';
        this.dataFileMeeting.fileTmp = '';
        const response = await this.findOneJAAttendace({id: this.dataFileMeeting.formatosId, tenderId: this.tenderId})
        if (response) {
          this.dataFileMeeting.id = response.id;
          this.dataFileMeeting.documento_url = response.documento_url;
          this.dataFileMeeting.tenderId = response.licitacionesId;
          this.dataFileMeeting.fileTmp = response.documento_url;
        } else {
          this.dataFileMeeting.tenderId = this.tenderId;
        }
        this.typeActually = 1;
        this.charge1 = true;
      }
      if (type === 2) {
        this.charge2 = false;
        this.dataFilePub.id = null;
        this.dataFilePub.documento_url = '';
        this.dataFilePub.fileTmp = '';
        const response = await this.findOneJAAttendace({id: this.dataFilePub.formatosId, tenderId: this.tenderId})
        if (response) {
          this.dataFilePub.id = response.id;
          this.dataFilePub.documento_url = response.documento_url;
          this.dataFilePub.tenderId = response.licitacionesId;
          this.dataFilePub.fileTmp = response.documento_url;
        } else {
          this.dataFilePub.tenderId = this.tenderId;
        }
        this.typeActually = 2;
        this.charge2 = true;
      }
      if (type === 3) {
        this.charge3 = false;
        this.dataFileConstant.id = null;
        this.dataFileConstant.documento_url = '';
        this.dataFileConstant.fileTmp = '';
        const response = await this.findOneJAAttendace({id: this.dataFileConstant.formatosId, tenderId: this.tenderId})
        if (response) {
          this.dataFileConstant.id = response.id;
          this.dataFileConstant.documento_url = response.documento_url;
          this.dataFileConstant.tenderId = response.licitacionesId;
          this.dataFileConstant.fileTmp = response.documento_url;
        } else {
          this.dataFileConstant.tenderId = this.tenderId;
        }
        this.typeActually = 3;
        this.charge3 = true;
      }
      if (type === 4) {
        this.charge4 = false;
        this.dataFileAs.id = null;
        this.dataFileAs.documento_url = '';
        this.dataFileAs.fileTmp = '';
        const response = await this.findOneJAAttendace({id: this.dataFileAs.formatosId, tenderId: this.tenderId})
        if (response) {
          this.dataFileAs.id = response.id;
          this.dataFileAs.documento_url = response.documento_url;
          this.dataFileAs.tenderId = response.licitacionesId;
          this.dataFileAs.fileTmp = response.documento_url;
        } else {
          this.dataFileAs.tenderId = this.tenderId;
        }
        this.typeActually = 4;
        this.charge4 = true;
      }
      if (type === 5) {
        this.charge5 = false;
        this.dataFileVg.id = null;
        this.dataFileVg.documento_url = '';
        this.dataFileVg.fileTmp = '';
        const response = await this.findOneJAAttendace({id: this.dataFileVg.formatosId, tenderId: this.tenderId})
        if (response) {
          this.dataFileVg.id = response.id;
          this.dataFileVg.documento_url = response.documento_url;
          this.dataFileVg.tenderId = response.licitacionesId;
          this.dataFileVg.fileTmp = response.documento_url;
        } else {
          this.dataFileVg.tenderId = this.tenderId;
        }
        this.typeActually = 5;
        this.charge5 = true;
      }
      this.overlay = false
    },
    async downloadImage(data) {
      try {
        const url = `${URL_API_IMAGES}${data}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },
    // archivos
    async findOneSurveillance() {
      const response = await this.findOneJASurveillanceUnit(this.tenderId);
      if (response) {
        this.idSurveillance = response.id
        this.dataSurveillance.formatData = response.formatoData;
        this.dataSurveillance.hora_minutos = response.hora_minutos;
        this.dataSurveillance.fecha_letra = response.fecha_letra;
        this.dataSurveillance.tenderId = response.licitacionesId;
        this.dataSurveillance.numero_oficio = response.numero_oficio;
        this.dataSurveillance.formatosId = response.formatosId;
      } else {
        this.dataSurveillance.tenderId = this.tenderId;

      }
    },
    async findOneAttendanceList() {
      const response = await this.findOneJAAttendanceList(this.tenderId);

      if (response) {
        this.idAttendanceList = response.id
        this.dataAttendanceList.formatData = response.formatoData;
        this.dataAttendanceList.hora_minutos = response.hora_minutos;
        this.dataAttendanceList.fecha_letra = response.fecha_letra;
        this.dataAttendanceList.tenderId = response.licitacionesId;
        this.dataAttendanceList.formatosId = response.formatosId;
      } else {
        this.dataAttendanceList.tenderId = this.tenderId;

      }
    },

    async findOneRetirement() {
      const response = await this.findOneJARetirement(this.tenderId);

      if (response) {
        this.idRetirement = response.id
        this.dataRetirement.formatData = response.formatoData;
        this.dataRetirement.hora_minutos = response.hora_minutos;
        this.dataRetirement.fecha_letra = response.fecha_letra;
        this.dataRetirement.tenderId = response.licitacionesId;
        this.dataRetirement.formatosId = response.formatosId;
        this.dataRetirement.textDate = response.textFechas;
      } else {
        this.dataRetirement.tenderId = this.tenderId;

      }
    },
    async findOnPublication() {
      const response = await this.findOneJAPublication(this.tenderId);

      if (response) {
        this.idConstancy = response.id
        this.dataPublication.formatData = response.formatoData;
        this.dataPublication.hora_minutos = response.hora_minutos;
        this.dataPublication.fecha_letra = response.fecha_letra;
        this.dataPublication.tenderId = response.licitacionesId;
        this.dataPublication.formatosId = response.formatosId;
      } else {
        this.dataPublication.tenderId = this.tenderId;

      }
    },
    async findMarketOne() {
      const resMinutes = await this.findOneJAMinutes({id: this.id, tenderId: this.tenderId});

      if (resMinutes) {
        this.idActa = resMinutes.id
        this.documentComplete = resMinutes.documentoCompleto
        this.data.formatData = resMinutes.formatoData;
        this.data.parrafo_default = resMinutes.parrafo_default;
        this.data.dudas_respuestas = resMinutes.dudas_respuestas;
        this.data.usuario_requirente = resMinutes.usuario_requirente;
      }


      this.formatosId = resMinutes.formatosId;
      const responseExternal = await this.findOneTenders(resMinutes.licitacionesId);
      if (responseExternal) {
        this.externalId = responseExternal.claveLicitacion;
      } else {
        this.externalId = null;
      }
    },
    // VALIDACTION FORMULARIOS
    async validateRequired() {
      if (!this.data.parrafo_default) {
        this.sweetAlertWarning('Campo Parrafo por Defecto es necesario');
        return false
      }
      if (!this.data.dudas_respuestas) {
        this.sweetAlertWarning('Campo Dudas y Respuestas es requerido');
        return false
      }
      if (!this.data.usuario_requirente) {
        this.sweetAlertWarning('Campo Usuario Requerimiento es requerido');
        return false
      }
      return true
    },
    async validateRequiredPublicacion() {
      if (!this.dataPublication.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.dataPublication.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },
    async validateRequiredRetiro() {
      if (!this.dataRetirement.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.dataRetirement.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },
    async validateRequiredAttendanceList() {
      if (!this.dataAttendanceList.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.dataAttendanceList.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },
    async validateRequiredLista() {
      if (!this.data.documento_url) {
        this.sweetAlertWarning('Campo de adjuntar documento es necesario');
        return false
      }
      return true
    },
    async validateRequiredOficioVigilancia() {
      if (!this.dataSurveillance.numero_oficio) {
        this.sweetAlertWarning('Campo Número de Oficio es necesario');
        return false
      }
      if (!this.dataSurveillance.hora_minutos) {
        this.sweetAlertWarning('Campo Hora y Minutos es necesario');
        return false
      }
      if (!this.dataSurveillance.fecha_letra) {
        this.sweetAlertWarning('Campo Fecha en Letra es requerido');
        return false
      }
      return true
    },

    // SUBMIT FORMS
    async submitForm() {
      const validateData = await this.validateRequired();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.updateJAMinutes({id: this.idActa, data: this.data});
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`);
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormPublicacion() {
      const validateData = await this.validateRequiredPublicacion();

      if (!validateData) {
        return
      }
      this.overlay = true;
      if (this.idConstancy) {
        const response = await this.updateJAPublication({id: this.idConstancy, data: this.dataPublication});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOnPublication();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createJAPublication(this.dataPublication);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOnPublication();
        } else {
          this.sweetAlertError('Error al crear')
        }

      }


      this.overlay = false;
    },

    async submitFormAttendanceList() {
      const validateData = await this.validateRequiredAttendanceList();

      if (!validateData) {
        return
      }
      this.overlay = true;

      if (this.idRetirement) {
        const response = await this.updateJAAttendanceList({id: this.idAttendanceList, data: this.dataAttendanceList});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOneRetirement();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createJAAttendanceList(this.dataAttendanceList);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneAttendanceList();
        } else {
          this.sweetAlertError('Error al crear')
        }
      }
      this.overlay = false;
    },
    async submitFormRetiro() {
      const validateData = await this.validateRequiredRetiro();

      if (!validateData) {
        return
      }
      this.overlay = true;

      if (this.idRetirement) {
        const response = await this.updateJARetirement({id: this.idRetirement, data: this.dataRetirement});
        if (!response.error) {
          this.sweetAlertSuccess('Se Edito correctamente')
          await this.findOneRetirement();
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createJARetirement(this.dataRetirement);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneRetirement();
        } else {
          this.sweetAlertError('Error al crear')
        }
      }
      this.overlay = false;
    },
    async submitFormLista() {
      const validateData = await this.validateRequiredLista();

      if (!validateData) {
        return
      }
      this.overlay = true;
      const response = await this.updateJAAttendance(this.data);
      if (!response.error) {
        this.sweetAlertSuccess('Se creo correctamente')
        await this.$router.push(`/licitaciones_documentos/${this.data.tenderId}`);
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    async submitFormOficioVigilancia() {
      const validateData = await this.validateRequiredOficioVigilancia();

      if (!validateData) {
        return
      }
      this.overlay = true;
      if (this.idSurveillance) {
        const response = await this.updateJASurveillanceUnit({id: this.idSurveillance, data: this.dataSurveillance});
        if (!response.error) {
          this.sweetAlertSuccess('Se edito correctamente')
          await this.findOneSurveillance()
        } else {
          this.sweetAlertError('Error al editar')
        }
      } else {
        const response = await this.createJASurveillanceUnit(this.dataSurveillance);
        if (!response.error) {
          this.sweetAlertSuccess('Se creo correctamente')
          await this.findOneSurveillance()
        } else {
          this.sweetAlertError('Error al crear')
        }
      }

      this.overlay = false;
    },

    // GENERACION DE ARCHIVOS
    async generateDataAttendanceListData() {
      this.overlay = true;
      const response = await this.generateHtmlFormatAttendanceList({id: this.idAttendanceList});
      if (!response.error) {
        this.documentGenerateAttendanceList = response;
        this.documentGenerateStatusAttendanceList = true;
      } else {
        this.documentGenerateAttendanceList = response;
        this.documentGenerateStatusAttendanceList = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerAttendanceList() {
      this.overlay = true;
      await this.downloadFileAttendanceList({id: this.idAttendanceList, externalId: this.externalId});
      this.overlay = false;
    },
    async generateDataMinutes() {
      this.overlay = true;
      const response = await this.generateHtmlFormatJAMinutes({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerMinutes() {
      this.overlay = true;
      await this.downloadFileJAMinutes({id: this.id, externalId: this.externalId});
      this.overlay = false;
    },

    async generateDataPub() {
      this.overlay = true;
      const response = await this.generateHtmlFormatPub({id: this.idConstancy});
      if (!response.error) {
        this.documentGenerateConstancy = response;
        this.documentGenerateStatusConstancy = true;
      } else {
        this.documentGenerateConstancy = response;
        this.documentGenerateStatusConstancy = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerPub() {
      this.overlay = true;
      await this.downloadFilePub({id: this.idConstancy, externalId: this.externalId});
      this.overlay = false;
    },

    async generateDataRet() {
      this.overlay = true;
      const response = await this.generateHtmlFormatRet({id: this.idRetirement});
      if (!response.error) {
        this.documentGenerateRet = response;
        this.documentGenerateStatusRet = true;
      } else {
        this.documentGenerateRet = response;
        this.documentGenerateStatusRet = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerRet() {
      this.overlay = true;
      await this.downloadFileRet({id: this.idRetirement, externalId: this.externalId});
      this.overlay = false;
    },

    async generateDataAtt() {
      this.overlay = true;
      const response = await this.generateHtmlFormatAtt({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerAtt() {
      this.overlay = true;
      await this.downloadFileAtt({id: this.id, externalId: this.externalId});
      this.overlay = false;
    },

    async generateDataSU() {
      this.overlay = true;
      const response = await this.generateHtmlFormatSU({id: this.idSurveillance});
      if (!response.error) {
        this.documentGenerateSurveillance = response;
        this.documentGenerateStatusSurveillance = true;
      } else {
        this.documentGenerateSurveillance = response;
        this.documentGenerateStatusSurveillance = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadFileServerSU() {
      this.overlay = true;
      await this.downloadFileSU({id: this.idSurveillance, externalId: this.externalId});
      this.overlay = false;
    },

    alertNexPrograming() {
      this.sweetAlertWarning('Proximamente')
    }
  }
  ,
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
